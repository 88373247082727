import PropTypes from 'prop-types'
import React from 'react'
// import pic01 from '../images/pic01.jpg'
// import pic02 from '../images/pic02.jpg'
// import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="How we help you?"
          className={`${this.props.article === 'service' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Challenge</h2>
         {/*  <span className="image main">
            <img src={pic01} alt="" />
          </span> */}
          <p>
            Digital ist easy?
          </p>
          <p>
            What often sounds <b>great</b> in theory, is <b>hard</b> in execution.
          </p>
          <p>
            <h3 className="major">How we can help? </h3>
            Providing experienced digital mindsets and implementation power for closely vetted partnerships. You could call us: "CTO as a Service". What we stand for:
            <br/>
            <br/>
            <ul>
              <li><b>excellence</b> - We strive for excellence. Expect proven systems.</li>
              <li><b>transparency</b> - We love transparency. Expect data focus.</li> 
              <li><b>truth</b> - We value truth. Expect straight talk.</li> 
            </ul>
          </p>
          <p>
            Get in touch <a href="mailto:info@ellissen.com">info@ellissen.com</a>.
          </p>
          {close}
        </article>

        <article
          id="notice"
          className={`${this.props.article === 'notice' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">notice</h2>
          {/* <span className="image main">
            <img src={pic02} alt="" />
          </span> */}
          <p>
            <h3>Responsible</h3>
            <b>Ellissen Consulting GmbH</b><br />
            Email: <a href="mailto:info@ellisen.com">info@ellissen.com</a>
          </p>
          <h3>Images</h3>
          <p>
            All images are either property of Ellissen Consulting GmbH or royalty free.
          </p>
          <h3>Disclaimer</h3>
          <p>
            Ellissen Consulting GmbH does not guarantee the correctness nor completeness of any information presented on the website, nor can Ellissen Consulting GmbH be made liable for any damages resulting from using information provided on this website. 
          </p>
          <h3>External Links</h3>
          <p>
          Ellissen Consulting GmbH is not liable for any external link that might be provided on this website.
          </p>
          <h3>Copyright</h3>
          <p>
            Written approval from Ellissen Consulting GmbH is required, if you want to use any of the content from this website.
          </p>
          {close}
        </article>

        <article
          id="privacy"
          className={`${this.props.article === 'privacy' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Privacy</h2>
          {/* <span className="image main">
            <img src={pic03} alt="" />
          </span> */}
          <p>
            <h3>Responsible</h3>
            <b>Ellissen Consulting GmbH</b><br />
            E-Mail: <a href="mailto:info@ellisen.com">info@ellissen.com</a>
          </p>
          <h3>Rights of data subject</h3>
          <ul>
            <li><b>Right of access:</b> You can get information on your data (Art. 15 GDPR).</li>
            <li><b>Right to rectification:</b> You can rectify your data (Art. 16 GDPR.)</li>
            <li><b>Right to erasure:</b> You can request deletion of your data (Art. 17 GDPR).</li>
            <li><b>Right to restriction:</b> You can restrict processing of your data (Art. 18 GDPR).</li>
            <li><b>Right to data portability:</b> You can get your data in digital form (Art. 20 GDPR).</li>
            <li><b>Right to object:</b> You can object to the processing of your data (Art. 21 GDPR)</li>
          </ul>
          <h3>Authorities</h3>
          <p>
            You can file a complaint with the authorities - find a list here: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">Data Privacy Officer of Germany</a>
          </p>
          <h3>Transfer of Data</h3>
          <p>
            We use SSL / TLS to encrypt data between the client e.g. your browser and our server.
          </p>
          <h3>Tracking</h3>
          <p>
            To improve and secure our website we use different technologies e.g. cookies.
          </p>
          <h3>Legal Ground</h3>
          <p>
            We value your data. We use as little data as possible. The data processing that we perform, is based on legitimate interest (Art. 6 f GDPR).
          </p>
          <h3>Purpose</h3>
          <p>
            We process data to improve and secure our service. 
          </p>
          <h3>Changes</h3>
          <p>
            We might change this policy at any time.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">contact</h2>
          {/* <span className="image main">
            <img src={pic02} alt="" />
          </span> */}
          <p>
        <br />
            Get in touch: <a href="mailto:info@ellissen.com">info@ellissen.com</a>.
          </p>
          {close}
        </article>

        {/* <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">contact</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">E-Mail</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Nachricht</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Abschicken" className="special" />
              </li>
              <li>
                <input type="reset" value="Zurücksetzen" />
              </li>
            </ul>
          </form>
          {close}
        </article> */}
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
